import { paymentTypes, paymentStatus } from 'constants';
import config from '../../../env.config';
import {
  addParamToURL
} from '../../utils/url';
import { categories, categoryRoute, normalizeCategory, productPageRoute, seasonInfo } from '../../constants';
import cookies from 'react-cookies';

export default class Utils {
  static objectOf(keys = [], object) {
    let variation = '';

    keys.forEach((key) => {
      if (object[key]) {
        variation = key;
      }
    });

    return variation;
  }

  static generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static getHighestProductDiscount = product => {
    if (
      product &&
      product.coupons &&
      Array.isArray(product.coupons) &&
      product.coupons.length > 0
    ) {
      let highestCoupon = null;
      product.coupons.forEach(coupon => {
        if (!highestCoupon || coupon.discount > highestCoupon.discount) {
          highestCoupon = coupon;
        }
      });

      if (highestCoupon && highestCoupon.discount > 0) {
        return `${parseInt(highestCoupon.discount * 100, 10)}%`;
      }
    }
    return null;
  };

  static replaceThumborURL = (urlWithThumbor) => {
    if (urlWithThumbor) {
      return `${urlWithThumbor || ''}`.replace('https://api-thumbor.hotmart.com/', 'https://static-media.hotmart.com/');
    }
    return '';
  }

  static mountLink(url, sck, src, off, offDiscount, draft, preview, xcod, origin, feature) {
    let finalURL = url;

    if (sck) {
      finalURL = addParamToURL(finalURL, 'sck', sck);
    }

    if (src) {
      finalURL = addParamToURL(finalURL, 'src', src);
    }

    if (off) {
      finalURL = addParamToURL(finalURL, 'off', off)
    }

    if (offDiscount) {
      finalURL = addParamToURL(finalURL, 'offDiscount', offDiscount)
    }

    if (xcod) {
      finalURL = addParamToURL(finalURL, 'xcod', xcod)
    }

    if (draft) {
      finalURL = addParamToURL(finalURL, 'draft', draft)
    }

    if (preview) {
      finalURL = addParamToURL(finalURL, 'preview', preview)
    }

    if (origin) {
      finalURL = addParamToURL(finalURL, 'origin', origin)
    }

    if (feature) {
      finalURL = addParamToURL(finalURL, 'hotfeature', feature)
    }

    return finalURL;
  };

  static verifyLanguage(language) {
    language = language ? language.toString().toLowerCase() : '';

    if (language) {
      if (language.match(/^pt/)) {
        return 'pt-br';
      } else if (language.match(/^en/)) {
        return 'en';
      } else if (language.match(/^es/)) {
        return 'es';
      } else if (language.match(/^fr/)) {
        return 'fr';
      } else if (language.match(/^ar/)) {
        return 'ar';
      } else {
        return 'en';
      }
    }
  }

  static getLanguageDetails = (lng) => {
    let _languageDetails = {
      language: 'en',
      country: ''
    };

    if (lng) {
      const _language = `${lng}`;

      if (_language.indexOf('-') !== -1) {
        const _splittedLanguage = _language.split('-');
        _languageDetails.language = _splittedLanguage[0].toLocaleLowerCase();
        _languageDetails.country = _splittedLanguage[1].toUpperCase();
      } else {
        _languageDetails.language = lng.toLocaleLowerCase();
      }
    }
    return _languageDetails;
  }

  static isTokenEncoded = token => decodeURIComponent(token) !== token;

  static encodeToken = token => (Utils.isTokenEncoded(token) ? token : encodeURIComponent(token));

  static substringText = (text, length = 0) => {

    if (!text) return '';

    const stringText = `${text}`;
    return `${stringText.substring(0, length).trim()}${stringText.length > length ? '...' : ''}`;
  }

  static isBillet = (paymentType) => {
    if (paymentType === paymentTypes.CASH_PAYMENT || paymentType === paymentTypes.BILLET) {
      return true;
    }

    return false;
  }

  static genericPayment = (paymentType) => {
    return paymentType === paymentTypes.PAYPAL || paymentType === paymentTypes.GOOGLE_PAY || paymentType === paymentTypes.SAMSUMG_PAY;
  }

  static isApproved = status => {
    return status !== paymentStatus.REFUSED;
  }

  static injectLauncher = (accountID, productID, id, token) => {
    if (window.document) {
      const script = document.getElementById('hotmart_launcher_script');

      if (!script) {
        let element = window.document.createElement("script");
        element.defer = true;
        element.async = true;
        element.crossOrigin = 'anonymous';
        element.id = 'hotmart_launcher_script'
        const script = " (function(l,a,u,n,c,h,e,r){l['HotmartLauncherObject']=c;l[c]=l[c]||function(){" +
          "                   module = ''; \n" +
          "                  (l[c].q=l[c].q||[]).push(arguments)},l[c].l=1*new Date();h=a.createElement(u),\n" +
          "                  e=a.getElementsByTagName(u)[0];h.async=1;h.src=n;e.parentNode.insertBefore(h,e)\n" +
          `                })(window,document,'script','${config.envConfig.ANALYTICS}/launcher.js','hot');\n` +
          "                hot('system','display');" +
          `                hot('account', '${accountID}')`;
        element.text = script;
        window.document.body.appendChild(element);

        if (id && token) {
          this.injectPixel(id, token)
        } else if (accountID && productID) {
          this.sendAccountID(productID)
        }
      }
    }
  }

  static injectGTM = () => {
    if (window.document) {
      const script = document.getElementById('hotmart_tag_manager');


      if (!script) {
        let element = window.document.createElement("script");
        const script = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.defer=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-KQFMS3D')`
        element.defer = true;
        element.async = true;
        element.crossOrigin = 'anonymous';
        element.id = 'hotmart_tag_manager'
        element.text = script;
        window.document.body.appendChild(element);
      }
    }
  }

  static injectScripts = () => {
    // Utils.injectNewRelic();
    // Utils.injectPollyfill();
  }

  static injectPollyfill = () => {
    const script = document.createElement('script');
    script.defer = true;
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.23.0/polyfill.min.js';
    script.async = true;
  }


  static getRandomNumber = (maxNumber) => {
    const random = Math.floor(Math.random() * maxNumber);
    return random === maxNumber ? random - 1 : random;
  }

  static injectNewRelic = () => {
    const newRelicConfig = `var NewRelicConf = { licenseKey: '3c1ca4c636', applicationID: '349913042', injectionPercentage: 50 };`;
    const newRelicConfigScript = document.createElement('script')
    const newRelicScript = document.createElement('script');
    newRelicScript.src = 'https://static.hotmart.com/js/injectNewRelic.js'
    newRelicScript.defer = true

    newRelicConfigScript.text = newRelicConfig;
    window.document.body.appendChild(newRelicConfigScript);
    window.document.body.appendChild(newRelicScript);
  }

  static injectHotjar = () => {
    // <!-- Hotjar Tracking Code for https://hotmart.com/search -->
    const script = ' (function(h,o,t,j,a,r){\n' +
      '        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n' +
      '        h._hjSettings={hjid:1808466,hjsv:6};\n' +
      '        a=o.getElementsByTagName(\'head\')[0];\n' +
      '        r=o.createElement(\'script\');r.async=1;\n' +
      '        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n' +
      '        a.appendChild(r);\n' +
      '      })(window,document,\'https://static.hotjar.com/c/hotjar-\',\'.js?sv=\');'
    const el = document.createElement('script');
    el.innerHTML = script;
    el.defer = true;
    el.async = true;

    window.document.body.appendChild(el);
  }

  static injectPixel = (id, token) => {
    setTimeout(() => {
      hot('hotmart_pixel_integration', token)
    }, 2000)
  };

  static sendAccountID = (productID) => {
    setTimeout(() => {
      hot('product_id', productID)
      hot('entity', 'product_page')
      hot('action', 'view')
      hot('send')
    }, 2000)
  };

  static isBlackNovemberDate = () => {
    const startDate = new Date(seasonInfo[0].START_DATE);
    const endDate = new Date(seasonInfo[0].END_DATE);
    const today = new Date();
    return startDate < today && today < endDate;
  }

  static isBlackNovember = (product, query) => {
    return (
      product?.coupon &&
      (!query?.coupon || query?.coupon === product?.coupon?.code) &&
      product?.coupon?.couponType === 'black_november_2023' &&
      Utils.isBlackNovemberDate()
    ) ? product?.coupon : false
  }

  static BlackNovemberDiscount = (coupon) => {
    return coupon?.discount ? parseInt(coupon.discount * 100) : 0;
  }

  static discountAmount = (oldPrice, newPrice) => {
    if (oldPrice !== newPrice) {
      return parseInt(((oldPrice - newPrice) / oldPrice) * 100).toFixed(0)
    }
  }

  static treatCheckoutError = (status, data, format) => {
    const defaultReturn = {
      ...data
    };

    if ((status === 412 || status === 460) && Utils.checkService(format)) {
      return { ...defaultReturn, checkoutClosed: true };
    } else if (data && data.error === 'order.checkout.orderprocessor.hotmart_checkout_error_hmc_033') {
      return { ...defaultReturn, checkoutClosed: true };
    }

    return { ...data };
  }

  static checkService = (format) => {
    return format !== categories.SERVICE_ONLINE;
  }

  static isEventOnline = (format) => {
    return format === categories.EVENT_ONLINE;
  }

  static getLanguageSymbol = (language) => {
    language = language ? language.toString().toLowerCase() : '';

    if (language) {
      if (language.match(/^pt/)) {
        return 'pt-br';
      } else if (language.match(/^en/)) {
        return 'en';
      } else if (language.match(/^es/)) {
        return 'es';
      } else if (language.match(/^fr/)) {
        return 'fr';
      } else if (language.match(/^ar/)) {
        return 'ar';
      } else {
        return language;
      }
    }
  };

  static treatLanguageExternal = language => {
    if (language?.match(/^pt/)) {
      return 'pt-br'
    }
    else return language;
  }

  static isPortuguese(language) {
    return Utils.getLanguageSymbol(language).match('pt');
  }

  static isMobileDevice(userAgent) {
    if (userAgent) {
      return Boolean(userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      ))
    } else {
      return false
    }
  }

  static getRemoteAddr(ip) {
    let ipAddress = ip;

    if (ipAddress?.match(/,/)) {
      ipAddress = ipAddress.split(',')[0];
    }

    if (ip.match(/ff/)) {
      ipAddress = "";
    }

    if (ipAddress?.match(/::1/) || ipAddress?.match(/::ffff/)) {
      ipAddress = '127.0.0.1'
    }

    return ipAddress;
  }

  static objectKeysToLowerCase = (origObj) => {
    return Object.keys(origObj).reduce(function (newObj, key) {
      let val = origObj[key];
      let newVal = (typeof val === 'object') ? Utils.objectKeysToLowerCase(val) : val;
      newObj[key.toLowerCase()] = newVal;
      return newObj;
    }, {});
  }

  static sanitize = (data, settings) => {
    data = data.replace(/[[/\]{}()*+?.,"\\^$|#\s]/g, ' ');
    return data
  };

  static fallbackCopyText = (text, callback) => {
    let textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      callback()
    } catch (err) {
      console.error(err);
    }

    document.body.removeChild(textArea);
  }

  static onCopy = async (text, callback) => {
    if (!navigator.clipboard) {
      Utils.fallbackCopyText(text, callback);
      return;
    }

    try {
      await navigator.clipboard.writeText(text)
      callback()
    } catch (err) {
      console.error(err)
    }
    return;
  }

  static getLanguageDefault(locale, headers) {

    if (cookies.load('hmLangCookie')) {
      return Utils.getLanguageSymbol(cookies.load('hmLangCookie'));
    }

    if (headers) {
      return headers;
    }

    return locale;
  }

  static validLanguage(router, locales, locale, acceptLanguage) {
    const languageDefault = this.getLanguageDefault(locale, acceptLanguage)

    if (!Utils.getLanguageSymbol(locale).toLocaleLowerCase().match(/pt/)) {
      router.push(router.asPath, router.asPath, { locale: locale });
    } else if (!languageDefault.toLocaleLowerCase().match(/pt/)) {
      const foundLocale = locales.filter(locale => locale === languageDefault);
      if (foundLocale.length >= 1) {
        router.push(router.asPath, router.asPath, { locale: languageDefault });
      } else {
        router.push(router.asPath, router.asPath, { locale: 'en' });
      }
    }
  }

  static scrollToSection = (ref) => {
    const section = document.getElementById(ref);
    const yPosition = section?.getBoundingClientRect().top + window.pageYOffset - 98;
    window.scrollTo({ top: yPosition, behavior: 'smooth' });
  };

  static isValidURL = (url) => {
    const regEx = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
    return regEx.test(url);
  }

  static prepareForResultsPage(language) {
    language = language ? language.toString().toLowerCase() : '';

    if (language) return productPageRoute[language];

    return productPageRoute.en;
  }

  static mountMarketplaceResultsPage(language) {
    return `${config.envConfig.APP}/${language?.toLowerCase()}/marketplace/${Utils.prepareForResultsPage(language)}`
  }

  static mountMarketplacePage(language) {
    return `${config.envConfig.APP}/${language?.toLowerCase()}/marketplace`
  }

  static mountHotmartHomePage(language) {
    return `${config.envConfig.APP}/${language?.toLowerCase()}`
  }

  static mountMarketplaceCategoryPage = (language) => {
    const categoryByRoute = categoryRoute[language];
    return `${config.envConfig.APP}/${language}/marketplace/${categoryByRoute}`
  }

  static mountMainUrlSalesPage(language, t) {
    return `${config.envConfig.APP}/${Utils.verifyLanguage(language)}/marketplace/${Utils.prepareForProductPage(Utils.verifyLanguage(language), t)}`
  }

  static mountUrlToShare(language, query) {
    return `${config.envConfig.APP}${Utils.internalRedirectSalesPage(language, query)}`
  }

  static internalRedirectSalesPage(language = 'pt-br', query) {
    const { slug, reference, off, sck, src, offDiscount, draft, preview, xcod, origin } = query;

    if (reference) {
      const url = `/${Utils.verifyLanguage(language)}/marketplace/${Utils.prepareForProductPage(Utils.verifyLanguage(language))}/${slug}/${reference}`;
      return Utils.mountLink(url, sck, src, off, offDiscount, draft, preview, xcod, origin)

    }

    const url = `/${Utils.verifyLanguage(language)}/marketplace/${Utils.prepareForProductPage(Utils.verifyLanguage(language))}/${slug}`
    return Utils.mountLink(url, sck, src, off, offDiscount, draft, preview, xcod, origin)
  }

  static prepareForProductPage(language) {
    language = language ? language.toString().toLowerCase() : '';

    if (language) return productPageRoute[language];

    return productPageRoute.en;
  }

  static generateCategoryByLanguage(language, category, t = {}) {
    if (!language) return '';

    language = language ? language.toString().toLowerCase() : '';

    const categoryByRoute = categoryRoute[language];
    const normalizeCategoryWithLanguage = normalizeCategory[language][category]

    if (!normalizeCategoryWithLanguage) {
      const product = Utils.prepareForProductPage(language);
      return `${config.envConfig.APP}/${language}/marketplace/${product}?q=${t(`category.${category}.name`)}`
    }

    return `${config.envConfig.APP}/${language}/marketplace/${categoryByRoute}/${normalizeCategoryWithLanguage}`
  }

  static mountStaticUrl(url) {
    const isProd = config.currentEnv !== 'development';

    if (isProd) {
      return `${config.envConfig.APP}/product/${url}`
    }

    return `${config.envConfig.APP}/${url}`

  }
}


