import config from '../env.config';

export const typesOfBadge = {
  VERIFIED_PROFILE: 'VERIFIED_PROFILE',
  CLIENT_PRODUCT_RATE_GOLD: 'CLIENT_PRODUCT_RATE_GOLD',
  CLIENT_PRODUCT_RATE_DIAMOND: 'CLIENT_PRODUCT_RATE_DIAMOND',
  CLIENT_PRODUCT_RATE_SILVER: 'CLIENT_PRODUCT_RATE_SILVER',
  BEST_SELLER: 'BEST_SELLER'
};

export const creditCards = {
  MASTER_CARD: 'MASTER_CARD',
  VISA: 'VISA',
  AMEX: 'AMEX',
  ELO: 'ELO',
  HIPERCARD: 'HIPERCARD',
  HIPER: 'HIPER',
  DINERS: 'DINERS'
};

export const links = {
  HELP: 'help',
  PRODUCTS: 'products',
  PROFILE: 'profile'
};

export const launchProductType = {
  PPL2: 'PPL2',
  PPL: 'PPL',
  PL: 'PL',
  L: 'L'
};

export const promptAI = {
  ADVANTAGES: 'product-advantages',
  TARGET: 'product-target-audience',
  FAQ: 'product-faq',
  FAQ_ITEMS: 6,
}

export const sourceParams = {
  CREATOR_MODAL: 'creator-modal',
  CREATOR_PAGE: 'creator-page',
  PRODUCTS_RECOMMENDED: 'products_recommend',
  PRODUCTS_HUB: 'products_hub'
};

export const eventsBlackNovember = {
  PRODUCT_DISCOUNT: 'is_product_with_bn_discount',
  CREATOR_BN: 'is_bn_producer'
}

export const TOTAL_STUDENTS = config.currentEnv === 'production' ? 300 : 50;

export const TOTAL_STUDENTS_TEST = {
  MIN: 100,
  MAX: 300
};

export const MODULES_CLUB = {
  MAX_IMPORTED: 50
}

export const TEXT_DESCRIPTION = {
  TOTAL_MIN: 680,
  TOTAL_MAX: 800,
  PARAGRAPHS_MIN: 2,
  PARAGRAPHS_MAX: 4
}

export const urls = {
  HELP: `${config.envConfig.HELP}`,
  PRODUCTS: `${config.envConfig.PRODUCTS}`,
  PROFILE: `${config.envConfig.PROFILE}`,
  LOGIN: `${config.envConfig.PLATFORM}/login?redirect=returnURL`,
  SIGNUP: `${config.envConfig.PLATFORM}/signup?redirect=returnURL`,
  LOGOUT: `${config.envConfig.PLATFORM}/logout?redirect=returnURL`,
  MARKETPLACE: `${config.envConfig.MARKETPLACE}`
};

export const cardTypes = {
  DEFAULT: 'DEFAULT',
  CARD_MORE: 'CARD_MORE',
  CARD_RATING: 'CARD_RATING',
  CARD_RATING_MORE: 'CARD_RATING_MORE',
  PRODUCT_CARD: 'PRODUCT_CARD',
  REVIEW_CARD: 'REVIEW_CARD'
};

export const checkoutTypes = {
  CHECKOUT_CLOSED: 'checkoutClosed',
  CHECKOUT_CUSTOMIZED: 'customizedCheckout',
  CHECKOUT: 'checkout'
}

export const formLeadSendStatus = {
  INITIAL: 0,
  SUCCESS: 1,
  ERROR: 2
}

export const accordionTypes = {
  FAQ: 'FAQ',
  SUMMARY: 'SUMMARY',
}

export const checkoutPayment = {
  LOCALE: {
    PT: 'pt-BR',
    ES: 'es-ES',
  },
  OPERATION: {
    ADD: 'ADD'
  },
  TYPE: {
    COUPON: 'COUPON',
    FULL_PRICE: 'FULL_PRICE',
    SUBSCRIPTION: 'SUBSCRIPTION',
    VAT: 'TAX_FEDERAL'
  }
}

export const reviewsFilter = {
  ALL: 'ALL',
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE'
}

export const productQueryString = {
  PRODUCT_MARKETPLACE: [
    {
      key: 'sck',
      value: 'HOTMART_SITE'
    }
  ],
  PRODUCT_SEASON: [
    {
      key: 'sck',
      value: 'HOTMART_BLACK_NOVEMBER'
    }
  ],
  PRODUCT_PAGE: [
    {
      key: 'sck',
      value: 'HOTMART_PRODUCT_PAGE'
    }
  ],
  PRODUCTS_RECOMMENDED: [
    {
      key: 'sck',
      value: 'HOTMART_SITE_SIMILAR_PRODUCTS'
    }
  ],
  PRODUCTS_HUB: [
    {
      key: 'sck',
      value: 'HOTMART_SITE_HUB'
    }
  ]
};

export const projectName = {
  MARKETPLACE: 'Marketplace',
  PRODUCT: 'Product'
}

export const paymentTypes = {
  GENERIC: 'GENERIC',
  CREDIT_CARD: 'CREDIT_CARD',
  BILLET: 'BILLET',
  PAYPAL: 'PAYPAL',
  GOOGLE_PAY: 'GOOGLE_PAY',
  SAMSUMG_PAY: 'SAMSUMG_PAY',
  CASH_PAYMENT: 'CASH_PAYMENT'
};

export const paymentStatus = {
  APPROVED: 'APPROVED',
  AUTHORIZED: '',
  AUTHORISED: 'AUTHORISED',
  REFUSED: 'REFUSED',
  RECEIVED: 'RECEIVED',
  FRAUD: 'FRAUD'
};

export const seasonInfo = [
  {
    NAME: 'BlackNovember',
    PREFIX: 'blacknovember',
    SLUG: 'black-november',
    COUPON_TYPE: 'black_november_2023',
    FILTER_SEPARATOR: '_-_',
    RESULTS_PAGE: `${config.envConfig.MARKETPLACE}/black-november`,
    LOGO: 'static/images/black-november-hm-logo.svg',
    START_DATE:
    process.env.ENV_CONFIG !== 'production'
      ? '2023/10/12 22:00:00 GMT-0300'
      : '2023/11/01 00:00:00 GMT-0300',
   END_DATE: '2023/12/09 23:59:59 GMT-0300',
  }
];

export const categories = {
  SERVICE_ONLINE: 'category.online_service.name',
  EVENT_ONLINE: 'category.online_event.name',
  EVENT_PRESENTIAL: 'category.etickets.name',
  COURSES_ONLINE: 'category.online_services.name'
};

export const categoriesLinks = [
  { slug: 'animals_and_plants' },
  { slug: 'software' },
  { slug: 'building_and_constructing' },
  { slug: 'gastronomy', useCategoryPage: true },
  { slug: 'personal_development', useCategoryPage: true },
  { slug: 'design' },
  { slug: 'law' },
  { slug: 'environment' },
  { slug: 'education', useCategoryPage: true },
  // { slug: "entertainment" },
  { slug: 'spirituality' },
  { slug: 'finance', useCategoryPage: true },
  { slug: 'general' },
  { slug: 'literature' },
  // { slug: "hobbies" },
  { slug: 'idioms' },
  { slug: 'internet' },
  { slug: 'fashion_and_beauty' },
  { slug: 'music_and_arts' },
  { slug: 'business_and_careers', useCategoryPage: true },
  // { slug: "relationships" },
  { slug: 'health_and_sports', useCategoryPage: true },
  // { slug: "sexuality" },
  { slug: 'information_technology' }
];

export const categoriesMarketplace = [
  { slug: 'animals_and_plants' },
  { slug: 'software' },
  { slug: 'building_and_constructing' },
  { slug: 'gastronomy', useCategoryPage: true },
  { slug: 'personal_development', useCategoryPage: true },
  { slug: 'design' },
  { slug: 'law' },
  { slug: 'environment' },
  { slug: 'education', useCategoryPage: true },
  // { slug: "entertainment" },
  { slug: 'spirituality' },
  { slug: 'finance', useCategoryPage: true },
  { slug: 'general' },
  { slug: 'literature' },
  // { slug: "hobbies" },
  { slug: 'idioms' },
  { slug: 'internet' },
  { slug: 'fashion_and_beauty' },
  { slug: 'music_and_arts' },
  { slug: 'business_and_careers', useCategoryPage: true },
  // { slug: "relationships" },
  { slug: 'health_and_sports', useCategoryPage: true },
  // { slug: "sexuality" },
  { slug: 'information_technology' }
];

export const sessionTypes = {
  SINGLE: 'SINGLE',
  PACKAGE: 'PACKAGE'
};

export const campaignStatus = {
  RUNNING: 'running',
  PAUSED: 'pause',
  SCHEDULE: 'schedule',
  DRAFT: 'draft',
  CLOSE: 'close'
}

export const campaignScreens = {
  HOME: "HOME",
  SEARCH_RESULT_PAGE: 'SEARCH_RESULT_PAGE',
  CATEGORY_PAGE: 'CATEGORY_PAGE',
  MENU: 'MENU',
  PRODUCT_CARD: 'PRODUCT_CARD',
  PRODUCT_PAGE: 'PRODUCT_PAGE',
  MAIN_PAGE: 'MAIN_PAGE'
}

export const campaignSections = {
  BANNER_TOP: "BANNER_TOP",
  BANNER_MIDDLE: "BANNER_MIDDLE",
  BANNER_FOOTER: "BANNER_FOOTER",
  STRIPE_TOP: "STRIPE_TOP",
  SIDE_BAR_PRICE: "SIDE_BAR_PRICE"
}

export const campaignBanner = {
  BACKGROUND: 'background_image',
  LOGO: 'logo_image',
  CTA: 'cta',
  CTA_URL: 'cta_url',
  TEXT: 'text'
}

export const campaignSideBar = {
  BACKGROUND: 'background_image',
  LOGO: 'logo_image',
  MAIN: 'main_image'
}

export const campaignPageRoute = {
  'pt-br': 'campanha',
  'en': 'campaign',
  'fr': 'campagne',
  'es': 'campana',
  'ar': 'campaign'
}

export const productPageRoute = {
  'pt-br': 'produtos',
  'en': 'products',
  'it': 'products',
  'de': 'products',
  'ja': 'products',
  'fr': 'des-produits',
  'es': 'productos',
  'ar': 'products'
}

export const categoryRoute = {
  'pt-br': 'categorias',
  'en': 'categories',
  'it': 'categories',
  'de': 'categories',
  'ja': 'categories',
  'fr': 'categories',
  'es': 'categorias',
  'ar': 'categories'
}

export const subsPerTopic = {
  'animais-e--pets': [ 'pet' ],
  'autoconhecimento-e-espiritualidade': [ 'astrologia', 'espiritualidade', 'meditacao', 'teologia' ],
  'carreira-e-desenvolvimento-pessoal': [ 'carreira', 'coaching', 'desenvolvimento-pessoal', 'oratoria' ],
  'culinaria-e-gastronomia': [ 'confeitaria', 'drinks', 'gastronomia', 'receitas' ],
  'design-e-fotografia': [ 'design', 'fotografia' ],
  'educacao-infantil-e-familia': [ 'maternidade' ],
  'engenharia-e-arquitetura': [ 'arquitetura', 'seguranca-do-trabalho' ],
  'ensino-e-estudo-academico': [
    'concurso',
    'direito',
    'educacao',
    'enem',
    'idiomas',
    'libras',
    'literatura',
    'politica',
    'psicologia'
  ],
  'financas-e-negocios': [ 'contabilidade', 'empreendedorismo', 'financas', 'investimentos' ],
  'hobbies-e-lazer': [ 'turismo' ],
  'manutencao-de-equipamentos': [ 'manutencao' ],
  'marketing-e-vendas': [ 'marketing-digital' ],
  'moda-e-beleza': [ 'cabelo', 'estetica', 'manicure', 'maquiagem', 'moda' ],
  'musica-e-artes': [ 'artesanato', 'desenho', 'musica' ],
  'plantas-e-ecologia': [ 'agricultura', 'plantas' ],
  'relacionamentos': [ 'relacionamento' ],
  'saude-e-esportes': [ 'emagrecimento', 'esporte', 'fisioterapia', 'saude' ],
  'tecnologia-e-desenvolvimento-de-software': [ 'excel', 'programacao', 'site' ],
  'unmapping_field': []
};

export const normalizeCategory = {
  'pt-br': {
    'education': 'educacao',
    'personal_development': 'desenvolvimento-pessoal',
    'business_and_careers': 'negocios-e-carreira',
    'health_and_sports': 'saude-e-esportes',
    'gastronomy': 'gastronomia',
    'finance': 'financas-e-investimentos',
    'animals_and_plants': 'animais-e-plantas',
    'relationships': 'relacionamentos',
    'law': 'direito',
    'literature': 'literatura',
    'spirituality': 'espiritualidade',
    'fashion_and_beauty': 'moda-e-beleza',
    'environment': 'ecologia-e-meio-ambiente',
    'software': 'apps-e-software',
    'idioms': 'idiomas',
    'music_and_arts': 'musica-e-artes',
    'information_technology': 'tecnologia-da-informacao',
    'building_and_constructing': 'casa-e-construcao',
    'design': 'design',
    'general': 'geral',
    'internet': 'internet'
  },
  'en': {
    'education': 'education',
    'personal_development': 'personal-development',
    'business_and_careers': 'business-and-careers',
    'health_and_sports': 'health-and-sports',
    'gastronomy': 'gastronomy',
    'finance': 'finance-and-investments',
    'animals_and_plants': 'animals-and-plants',
    'relationships': 'relationships',
    'law': 'law',
    'literature': 'literature',
    'fashion_and_beauty': 'fashion-and-beauty',
    'spirituality': 'spirituality',
    'environment': 'environment',
    'software': 'apps-and-software',
    'idioms': 'languages',
    'music_and_arts': 'arts-and-music',
    'information_technology': 'information-technology',
    'building_and_constructing': 'building-and-constructing',
    'design': 'design',
    'general': 'general',
    'internet': 'internet'
  },
  'es': {
    'education': 'educacion',
    'personal_development': 'desarrollo-personal',
    'business_and_careers': 'negocios-y-carrera',
    'health_and_sports': 'salud-y-deportes',
    'gastronomy': 'culinaria-y-gastronomía',
    'finance': 'finanzas-e-inversiones',
    'animals_and_plants': 'animales-y-plantas',
    'relationships': 'relaciones',
    'law': 'derecho',
    'spirituality': 'espiritualidad',
    'fashion_and_beauty': 'moda-y-belleza',
    'literature': 'literatura',
    'environment': 'ecologia-y-medio-ambiente',
    'software': 'apps-y-software',
    'idioms': 'idiomas',
    'music_and_arts': 'musica-y-artes',
    'information_technology': 'tecnologia-de-la-informacion',
    'building_and_constructing': 'casa-y-construccion',
    'design': 'diseno',
    'general': 'general',
    'internet': 'internet'
  },
  'fr': {
    'education': 'education',
    'personal_development': 'developpement-personnel',
    'business_and_careers': 'affaires-et-carriere',
    'health_and_sports': 'sante-et-sports',
    'gastronomy': 'cuisine-et-gastronomie',
    'finance': 'finances-et-investissements',
    'animals_and_plants': 'animaux-et-plantes',
    'relationships': 'relations',
    'law': 'droit',
    'fashion_and_beauty': 'mode-et-beauté',
    'spirituality': 'littérature',
    'environment': 'ecologie-et-environnement',
    'software': 'appli-et-logiciels',
    'idioms': 'langues',
    'music_and_arts': 'arts-et-musique',
    'information_technology': 'technologies-de-l-information',
    'building_and_constructing': 'maison-et-construction',
    'design': 'design',
    'general': 'general',
    'internet': 'internet'
  },
  'ar': {
    'education': 'education',
    'personal_development': 'personal-development',
    'business_and_careers': 'business-and-careers',
    'health_and_sports': 'health-and-sports',
    'gastronomy': 'gastronomy',
    'finance': 'finance',
    'animals_and_plants': 'animals-and-plants',
    'relationships': 'relationships',
    'law': 'law',
    'literature': 'literatura',
    'fashion_and_beauty': 'fashion-and-beauty',
    'spirituality': 'spirituality',
    'environment': 'environment',
    'software': 'software',
    'idioms': 'idioms',
    'music_and_arts': 'arts-and-music',
    'information_technology': 'information-technology',
    'building_and_constructing': 'building-and-constructing',
    'design': 'design',
    'general': 'general',
    'internet': 'internet'
  },
  ja: {
    'education': 'education',
    'personal_development': 'personal-development',
    'business_and_careers': 'business-and-careers',
    'health_and_sports': 'health-and-sports',
    'gastronomy': 'gastronomy',
    'finance': 'finance-and-investments',
    'animals_and_plants': 'animals-and-plants',
    'relationships': 'relationships',
    'law': 'law',
    'literature': 'literature',
    'fashion_and_beauty': 'fashion-and-beauty',
    'spirituality': 'spirituality',
    'environment': 'environment',
    'software': 'apps-and-software',
    'idioms': 'languages',
    'music_and_arts': 'arts-and-music',
    'information_technology': 'information-technology',
    'building_and_constructing': 'building-and-constructing',
    'design': 'design',
    'general': 'general',
    'internet': 'internet'
  },
  de: {
    'education': 'education',
    'personal_development': 'personal-development',
    'business_and_careers': 'business-and-careers',
    'health_and_sports': 'health-and-sports',
    'gastronomy': 'gastronomy',
    'finance': 'finance-and-investments',
    'animals_and_plants': 'animals-and-plants',
    'relationships': 'relationships',
    'law': 'law',
    'literature': 'literature',
    'fashion_and_beauty': 'fashion-and-beauty',
    'spirituality': 'spirituality',
    'environment': 'environment',
    'software': 'apps-and-software',
    'idioms': 'languages',
    'music_and_arts': 'arts-and-music',
    'information_technology': 'information-technology',
    'building_and_constructing': 'building-and-constructing',
    'design': 'design',
    'general': 'general',
    'internet': 'internet'
  },
  it: {
    'education': 'education',
    'personal_development': 'personal-development',
    'business_and_careers': 'business-and-careers',
    'health_and_sports': 'health-and-sports',
    'gastronomy': 'gastronomy',
    'finance': 'finance-and-investments',
    'animals_and_plants': 'animals-and-plants',
    'relationships': 'relationships',
    'law': 'law',
    'literature': 'literature',
    'fashion_and_beauty': 'fashion-and-beauty',
    'spirituality': 'spirituality',
    'environment': 'environment',
    'software': 'apps-and-software',
    'idioms': 'languages',
    'music_and_arts': 'arts-and-music',
    'information_technology': 'information-technology',
    'building_and_constructing': 'building-and-constructing',
    'design': 'design',
    'general': 'general',
    'internet': 'internet'
  },
}
